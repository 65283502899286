


#logo {
	width: rem-calc(200px);
	//padding: rem-calc(40px);
}

.container {
	height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
